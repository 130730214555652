<template>
 <div id="base">
   <div class="main-display" id="main-display">
     <a-spin :spinning="spinning" :delay="delayTime">
       <div v-if="Object.keys(room).length !== 0">
           <div class="col-md-12 mt-2">
             <div class="row">
               <div class="col-md-7">
                 <div class="header mb-4">
                   <h4 style="font-weight: bold; font-size: 30px !important; color: white">Conference Room: {{ room.name}}</h4>
                   <router-link :to="{ name: 'roomBookingCreate', params: { room_id: room.id } }"  tag="a"><span class="btn btn-danger" style="color: black; font-size: 16px;">Click to book your "Conference Room" </span></router-link>
                    <h5 class="mt-2" style="color: white"><span style="color: red">*</span>Book your room atleast 2 hours prior</h5>
                 </div>
                 <template>
                   <template v-if="current_room_booking !== null">
                     <div class="running-meeting">
                       <h5 style="font-size: 24px !important; font-weight: bold;">Meeting ongoing</h5>
                       <p style="font-size: 22px; margin-bottom: 0px !important;">Department: {{ current_room_booking.department.department_name }}</p>
                       <p style="font-size: 22px; margin-bottom: 0px !important;">Purpose: {{ current_room_booking.meeting_purpose }}</p>
                       <p style="font-size: 18px">Meeting until {{ current_room_booking.last_time_slot_explode_second_to_explode_first }}:00 {{ current_room_booking.last_time_slot_12_format }}</p>
                     </div>
                   </template>
                   <span v-if="next_room_bookings.length > 0">
                   <div class="next-meeting" :class="next_room_bookings.length > 3 ? 'for-next-meeting-scroll' : ''">
                       <h5 style="font-size: 24px !important; font-weight: bold;">Upcoming meetings</h5>
                       <span v-for="(next_room_booking, index) in next_room_bookings" :key="index">
                         <p style="font-size: 22px; margin-bottom: 0px !important;">Department: {{next_room_booking.department.department_name}}</p>
                         <p style="font-size: 22px; margin-bottom: 0px !important;">Purpose: {{next_room_booking.meeting_purpose }}</p>
                         <p style="font-size: 18px">Meeting will start from {{next_room_booking.first_time_slot_explode_first}}:00 {{ next_room_booking.first_time_slot_12_format }}</p>
                         <template v-if="index !== Object.keys(next_room_bookings).length - 1">
                           <hr>
                         </template>
                       </span>
                   </div>
                      </span>
                 </template>
               </div>
               <div class="col-md-5 ">
                 <div class="col-md-12">
                   <div class="row pull-right">
                     <img style="float: right" src="/resources/images/Daraz_Logo_Orange.png" alt="" width="150px" height="66px">
                   </div>
                 </div>
               </div>
             </div>
           </div>
           <div class="clock-footer">
             <div  class="action-clock">
               <div id="clock pull-right" style="float: right">
                 <p class="date">{{ currentDay }}, {{ currentDate }}</p>
                 <p class="time">{{ currentTime }}</p>
               </div>
             </div>
           </div>
           <div class="action-button">
             <div>
               <button v-if="!displayToggle" @click.prevent="toggleFullScreen" style="padding: 2px; cursor: pointer; float: left; margin-top: 29px !important;"><i class="fa fa-expand" aria-hidden="true"></i> Full Scene Mode</button>
               <button v-if="displayToggle" @click.prevent="toggleFullScreen" style="padding: 2px; cursor: pointer; float: left; margin-top: 29px !important;"><i class="fa fa-compress" aria-hidden="true"></i> Close Full Scene Mode</button>
             </div>
           </div>
       </div>
     </a-spin>
<!--     <div class="col-md-12">-->
<!--       <a-skeleton active :loading="loading"></a-skeleton>-->
<!--     </div>-->
   </div>
 </div>
</template>

<script>
import apiClient from '@/services/axios'
import { Empty } from 'ant-design-vue'
import { format } from 'date-fns'
import moment from 'moment'

const getCurrentTime = () => format(new Date(), 'h:mm:ss a')

export default {
  name: 'display',
  data() {
    return {
      displayToggle: false,
      loading: false,
      simpleImage: Empty.PRESENTED_IMAGE_SIMPLE,
      currentDay: moment().format('dddd'),
      currentTime: moment().format('LTS'),
      currentDate: moment().format('LL'),
      room: {},
      current_room_booking: {},
      next_room_bookings: {},
      timer: '',
      spinning: false,
      delayTime: 500,
    }
  },
  created() {
    document.addEventListener('fullscreenchange', this.FSHandler)
    document.addEventListener('webkitfullscreenchange', this.FSHandler)
    document.addEventListener('mozfullscreenchange', this.FSHandler)
    document.addEventListener('MSFullscreenChange', this.FSHandler)
    setInterval(() => this.updateCurrentTime(), 1000)
    this.timer = setInterval(this.getRooms, 300000)
  },
  mounted() {
    this.getRooms()
  },
  methods: {
    getRooms() {
      this.spinning = true
      const roomId = this.$route.params.room_id
      apiClient.get('api/display/room/b/' + roomId)
        .then(response => {
          this.spinning = false
          this.room = response.data.room
          this.current_room_booking = response.data.current_room_booking
          this.next_room_bookings = response.data.next_room_bookings
        })
        .catch(error => {
          this.spinning = false
          console.log(error)
        })
    },
    toggleFullScreen() {
      if ((document.fullScreenElement && document.fullScreenElement !== null) || (!document.mozFullScreen && !document.webkitIsFullScreen)) {
        this.displayToggle = true
        if (document.documentElement.requestFullScreen) {
          document.documentElement.requestFullScreen()
        } else if (document.documentElement.mozRequestFullScreen) { /* Firefox */
          document.documentElement.mozRequestFullScreen()
        } else if (document.documentElement.webkitRequestFullScreen) { /* Chrome, Safari & Opera */
          document.documentElement.webkitRequestFullScreen(Element.ALLOW_KEYBOARD_INPUT)
        } else if (document.msRequestFullscreen) { /* IE/Edge */
          document.documentElement.msRequestFullscreen()
        }
      } else {
        this.displayToggle = false
        if (document.cancelFullScreen) {
          document.cancelFullScreen()
        } else if (document.mozCancelFullScreen) { /* Firefox */
          document.mozCancelFullScreen()
        } else if (document.webkitCancelFullScreen) { /* Chrome, Safari and Opera */
          document.webkitCancelFullScreen()
        } else if (document.msExitFullscreen) { /* IE/Edge */
          document.msExitFullscreen()
        }
      }
    },
    FSHandler() {
      if (!document.fullscreenElement && !document.webkitIsFullScreen && !document.mozFullScreen && !document.msFullscreenElement) {
        this.displayToggle = false
      } else {
        this.displayToggle = true
      }
    },
    updateCurrentTime() {
      this.currentTime = getCurrentTime()
    },
    cancelAutoUpdate () {
      clearInterval(this.timer)
    },
  },
  beforeDestroy () {
    this.cancelAutoUpdate()
  },
}
</script>

<style scoped>
#base,
.main-display {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  height: 100vh;
}
.main-display{
  height: 100%;
  background: url('/resources/images/room_booking-display-v3.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
.running-meeting{
  background-color: #ffffff;
  padding: 16px;
  color: #0c0c0cad;
}
.next-meeting{
  background-color: #b8babfb5;
  padding: 16px;
  color: #0c0c0ccf;
}

.for-next-meeting-scroll::-webkit-scrollbar-track
{
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
  background-color: #F5F5F5;
}

.for-next-meeting-scroll::-webkit-scrollbar
{
  width: 10px;
  background-color: #F5F5F5;
}

.for-next-meeting-scroll::-webkit-scrollbar-thumb
{
  background-color: #F75506;
  border: 2px solid #F75506;
}

.for-next-meeting-scroll{
  overflow-y: scroll;
  height: 75vh;
}
.action-button {
  position: fixed;
  float: left;
  bottom:0;
  height: 60px;
}
.action-clock {
  position: fixed;
  bottom:0;
  right:0;
  left:0;
}
.time {
  font-size: 7em;
}
.shadow {
  text-shadow: 0 0 15px rgba(100, 100, 100, 0.75);
}
#clock {
  float: right;
  position: fixed;
  bottom:0;
  height: 100px;
  transform: translate(-50%, -50%);
  color: #ffffff;
  text-shadow: 0 0 20px rgba(10, 179, 230, 0.97),  0 0 20px rgba(10, 175, 230, 0);
}
.time {
  letter-spacing: 0.05em;
  font-size: 5em;
  color: #ffffff !important;
}
.date {
  letter-spacing: 0.1em;
  margin-bottom: 0px !important;
  font-size: 30px !important;
  text-align: center;
  color: #ffffff !important;
}
/*.nextRoomListScroll {*/
/*  display: block;*/
/*  overflow-y: scroll;*/
/*  width: 1em;*/
/*}*/
.nextRoomListScroll {
  overflow-y: auto;
}
.nextRoomListScroll::-webkit-scrollbar {
  width: 10px;;
}

.nextRoomListScroll::-webkit-scrollbar-track {
  background-color: darkgrey;
}

.nextRoomListScroll::-webkit-scrollbar-thumb {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}
</style>
